import React from "react";
import Axios from "axios";
import { BrowserRouter, Routes, Route, Navigate } from "react-router";
import { PrivateRoute, AuthProvider } from "./auth";
import { View } from "components/lib";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// 404
import { NotFound } from "views/error/404";

// Tailwind CSS
import "../css/output.css";
import { createTheme, ThemeProvider } from "@mui/material";
import ErrorInterceptor from "./errorInterceptor";

// Settings
const Settings = require("settings.json");

const routes = [
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/links").default,
  ...require("routes/bulk-generate").default,
  ...require("routes/templates").default,
  ...require("routes/folders").default,
  ...require("routes/auth").default,
  ...require("routes/website").default,
  ...require("routes/api").default,
  ...require("routes/user").default,
  ...require("routes/masterAccounts").default,
  ...require("routes/masterContacts").default,
  ...require("routes/checkout").default,
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#1E88D4",
    },
    // secondary: {
    //   main: "#ff5e00",
    // },
    success: {
      main: "#45ad58",
      contrastText: "#fff",
      dark: "#3b974c",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // color: "rgba(0, 0, 0, 0.9)",
        },
      },
    },
  },
});

export default function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  return (
    <Elements stripe={null}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <ErrorInterceptor />
            <Routes>
              {routes.map((route) => {
                if (
                  [
                    "/signin",
                    "/signup",
                    "/forgotpassword",
                    "/resetpassword",
                  ].includes(route.path)
                ) {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        user ? (
                          <Navigate to="/links" replace />
                        ) : (
                          <View
                            display={route.view}
                            layout={route.layout}
                            title={route.title}
                          />
                        )
                      }
                    />
                  );
                }

                // Default route logic
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.permission ? (
                        <PrivateRoute permission={route.permission}>
                          <View
                            display={route.view}
                            layout={route.layout}
                            title={route.title}
                          />
                        </PrivateRoute>
                      ) : (
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      )
                    }
                  />
                );
              })}

              {/* 404 */}
              <Route
                path="*"
                element={
                  <View
                    display={NotFound}
                    layout="home"
                    title="404 Not Found"
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Elements>
  );
}
