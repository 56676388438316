import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { AuthContext, Logo } from "components/lib";
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Card,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Check, Palette, Share, BarChart } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

// Validation schema using Yup
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string().required("Please enter your name"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain an uppercase letter, a lowercase letter, a number, and a special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
  industry: yup.string().required("Please select an industry"),
});

export function Signup() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post("/api/account", data, {
        headers: { "Content-Type": "application/json" },
      });
      context.signin(response);
      navigate("/next-step"); // Redirect to the next step after signup
    } catch (error) {
      console.error("Signup error:", error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#1f2937",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container sx={{ maxWidth: 1200 }}>
        {/* Left side with features */}
        <Grid
          size={{ md: 6, xs: 12 }}
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box textAlign="left" maxWidth={500} mx="auto">
            <Typography variant="h4" gutterBottom>
              The ultimate toolkit to design, control, and monitor adaptive QR
              Codes
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Start with a 14-day free trial, featuring up to 1,000 adaptive QR
              codes, design themes, multi-user access, and bulk QR creation.
              Discover some of the powerful tools at your fingertips:
            </Typography>
            <Box component="ul" sx={{ listStyleType: "none", paddingLeft: 0 }}>
              <Box component="li" display="flex" alignItems="center" mb={2}>
                <Check sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Design flexible QR codes you can edit, update, or change
                  without reprinting.
                </Typography>
              </Box>
              <Box component="li" display="flex" alignItems="center" mb={2}>
                <Palette sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Personalize your QR codes with custom colors, logos, and
                  subdomains.
                </Typography>
              </Box>
              <Box component="li" display="flex" alignItems="center" mb={2}>
                <Share sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Collaborate on QR projects seamlessly with your team.
                </Typography>
              </Box>
              <Box component="li" display="flex" alignItems="center" mb={2}>
                <BarChart sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Gain insights with trackable QR data and downloadable
                  analytics.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Right side with signup form */}
        <Grid
          size={{ md: 6, xs: 12 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card sx={{ width: "100%", maxWidth: 500, padding: 6, boxShadow: 3 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Start your 14-day free trial of the Professional plan
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Your Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />

              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />

              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small" error={!!errors.industry}>
                    <InputLabel>Select an industry</InputLabel>
                    <Select {...field} size="small" label="Select an industry">
                      {[
                        "Personal use",
                        "Banking",
                        "Consulting",
                        "Government",
                        "Healthcare",
                        "Hotels & Resorts",
                        "Restaurants & Bars",
                        "Non-profits",
                        "Event management",
                        "Real estate",
                        "Education",
                        "Gym & Wellness",
                        "Retail",
                        "Software & IT",
                        "Publishing",
                        "Arts & Music",
                        "Travel",
                        "Recruiting & HR",
                        "Media & TV",
                        "FMCG",
                        "Insurance",
                        "Marketing & Advertising",
                        "Transportation",
                        "Manufacturing",
                        "Design",
                      ].map((industry) => (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" color="error">
                      {errors.industry?.message}
                    </Typography>
                  </FormControl>
                )}
              />

              <Typography
                variant="body2"
                align="left"
                sx={{ mt: 2, mb: 2, color: "text.secondary" }}
              >
                By clicking "Start free trial" you agree with Prolinks’s{" "}
                <a href="/terms" style={{ color: "primary.main" }}>
                  Terms & Conditions
                </a>
                ,{" "}
                <a href="/privacy" style={{ color: "primary.main" }}>
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="/cookie-policy" style={{ color: "primary.main" }}>
                  Cookie Policy
                </a>
                .
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 1 }}
              >
                Start free trial
              </Button>

              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  • No credit card required
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  • Up to 100 scans per QR during your trial period
                </Typography>
              </Box>
            </form>

            <Divider sx={{ my: 2 }} />

            <Typography variant="body2" sx={{ textAlign: "left" }}>
              Already on Prolinks?{" "}
              <a href="/signin" style={{ color: "primary.main" }}>
                Log in
              </a>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
