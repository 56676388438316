import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { AuthContext } from "components/lib";

export function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Extract token from query string
  const token = new URLSearchParams(location.search).get("token");

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[a-z]/, "Password must contain a lowercase letter")
      .matches(/\d/, "Password must contain a number"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setSubmitting(true);
    setErrorMessage(""); // Clear previous errors
    try {
      const response = await axios.post("/api/auth/password/reset", {
        email: data.email,
        password: data.password,
        jwt: token,
      });

      if (response.data["2fa_required"]) {
        navigate(`/signin/otp?token=${response.data.token}`);
      } else {
        context.signin(response);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        error.response?.data?.message || "Failed to reset the password."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={12}
    >
      <Card sx={{ maxWidth: 400, p: 4, textAlign: "center" }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Reset Your Password
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Enter your email address and your new password
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  size="small"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="New Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  size="small"
                  margin="normal"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
              )}
            />
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              sx={{ mt: 2 }}
              disabled={submitting}
            >
              {submitting ? "Setting Password..." : "Set New Password"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}
