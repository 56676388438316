import { Checkout } from "views/payments/checkout";
import { PlansAndPayments } from "views/payments/payments";

const Routes = [
  {
    path: "/plans-and-payments",
    view: PlansAndPayments,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/checkout",
    view: Checkout,
    layout: "app",
    permission: "user",
    title: "",
  },
];

export default Routes;
