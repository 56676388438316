import React from "react";
import { Pricing } from "views/website/pricing";

export function PlansAndPayments() {
  return (
    <>
      <Pricing></Pricing>
    </>
  );
}
