import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Stack,
  InputAdornment,
  Alert,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useWatch } from "react-hook-form";
import StatsIcon from "@mui/icons-material/Assessment";
import { useAPI } from "components/lib";

export function LinkConfigurations({ control, errors, isEditMode }) {
  const dynamicUrl = process.env.REACT_APP_DYNAMIC_URL;
  const {
    loading: loadingFolders,
    data: folders,
    refetchFolders,
  } = useAPI(`/api/folders`);

  const scheduleActive = useWatch({
    control,
    name: "linkConfigurations.scheduleActive",
  });
  const urlActive = useWatch({
    control,
    name: "linkConfigurations.urlActive",
  });
  const scanLimitActive = useWatch({
    control,
    name: "linkConfigurations.scanLimitActive",
  });

  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StatsIcon sx={{ fontSize: 40, color: "primary.main", mr: 2 }} />
          <Box>
            <Typography variant="h6">Configurations</Typography>
            <Typography variant="subtitle2">
              Add extra layer of customization.
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {/* Folder Selection */}
            <Controller
              name="linkConfigurations.folder_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={folders}
                  getOptionLabel={(option) => option.name || ""}
                  loading={loadingFolders}
                  onChange={(event, value) =>
                    field.onChange(value ? value.id : null)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Folder"
                      fullWidth
                      size="small"
                      error={!!errors?.linkConfigurations?.folder_id}
                      helperText={
                        errors?.linkConfigurations?.folder_id?.message || ""
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingFolders ? (
                              <CircularProgress size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            {/* Schedule Activation Switch */}
            <Controller
              name="linkConfigurations.scheduleActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} size="small" checked={!!field.value} />
                  }
                  label={
                    <Typography variant="body2">Activate Schedule</Typography>
                  }
                />
              )}
            />
            {/* Start Date */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="linkConfigurations.startDate"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    label="Start Date"
                    disabled={!scheduleActive}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: !!errors?.linkConfigurations?.startDate,
                        helperText:
                          errors?.linkConfigurations?.startDate?.message || "",
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="linkConfigurations.endDate"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    label="End Date"
                    disabled={!scheduleActive}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        error: !!errors?.linkConfigurations?.endDate,
                        helperText:
                          errors?.linkConfigurations?.endDate?.message || "",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Controller
              name="linkConfigurations.urlActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} size="small" checked={!!field.value} />
                  }
                  label={
                    <Typography variant="body2">Use Custom URL</Typography>
                  }
                />
              )}
            />
            <Controller
              name="linkConfigurations.customUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custom URL"
                  fullWidth
                  size="small"
                  disabled={!urlActive}
                  error={!!errors?.linkConfigurations?.customUrl}
                  helperText={
                    errors?.linkConfigurations?.customUrl?.message || ""
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="body2">{dynamicUrl}/</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {isEditMode && (
              <Alert severity="warning">
                Changes to the short URL of an already published QR code will
                cause the previous version of the QR code to be overwritten and
                it will no longer be accessible.
              </Alert>
            )}
            {/* Scan Limit */}
            <Controller
              name="linkConfigurations.scanLimitActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} size="small" checked={!!field.value} />
                  }
                  label={
                    <Typography variant="body2">Enable Scan Limit</Typography>
                  }
                />
              )}
            />
            {/* Scan Limit Number Input */}
            <Controller
              name="linkConfigurations.scanLimit"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Scan Limit"
                  type="number"
                  fullWidth
                  size="small"
                  disabled={!scanLimitActive}
                  error={!!errors?.linkConfigurations?.scanLimit}
                  helperText={
                    errors?.linkConfigurations?.scanLimit?.message || ""
                  }
                />
              )}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
