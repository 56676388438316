import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TextField,
  Button,
  Box,
  Typography,
  Link as MuiLink,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { AuthContext } from "components/lib";
import axios from "axios";

export function SignupUser() {
  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf("?id=") + 4, url.indexOf("&email"));
  const email = url.substring(url.indexOf("&email") + 7);

  const [password, setPassword] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required("Please enter your first name"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[a-z]/, "Password must contain a lowercase letter")
      .matches(/\d/, "Password must contain a number"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: email,
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const newUser = await axios.post("/api/user", {
        ...data,
        invite_id: id,
      });
      context.signin(newUser);
    } catch (error) {
      console.error("Signup failed:", error);
    }
  };

  const isRequirementMet = (pattern) => pattern.test(password);

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      mt={8}
    >
      <Grid item xs={12} sm={12}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" gutterBottom>
            Create Your Account
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%", maxWidth: 400 }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    field.onChange(e);
                  }}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              sx={{ mt: 2 }}
            >
              Create Account
            </Button>
          </Box>

          <Box mt={2}>
            <Typography>
              Already registered?{" "}
              <MuiLink href="/signin" color="primary">
                Sign In
              </MuiLink>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* <Grid item xs={12} sm={3}>
        <Box sx={{ border: "1px solid #ddd", borderRadius: 2, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Password Requirements
          </Typography>
          <Box display="flex" alignItems="center" mb={1}>
            {isRequirementMet(/^.{8,}$/) ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
            <Typography sx={{ ml: 1 }}>At least 8 characters</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            {isRequirementMet(/[A-Z]/) ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
            <Typography sx={{ ml: 1 }}>
              At least one uppercase letter
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            {isRequirementMet(/[a-z]/) ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
            <Typography sx={{ ml: 1 }}>
              At least one lowercase letter
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            {isRequirementMet(/\d/) ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
            <Typography sx={{ ml: 1 }}>At least one number</Typography>
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
}
