import React, { useState } from "react";
import Axios from "axios";
import { Logo, Event } from "components/lib";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export function Onboarding(props) {
  const navigate = useNavigate();
  const hash = window.location.hash;
  const [activeView, setActiveView] = useState(
    hash ? hash.substring(1) - 1 : 0
  );

  if (!props?.views?.length) return false;

  const view = props.views[activeView];

  async function goTo(view) {
    window.location.hash = view + 1;
    setActiveView(view);
  }

  async function finish() {
    if (props.save) await Axios.patch("/api/user", { onboarded: true });
    navigate(props.onFinish || "/links");
  }

  return (
    <Paper
      sx={{
        width: { xs: "95%", md: "44em" },
        boxShadow: 3,
        position: "relative",
      }}
    >
      <Button
        variant="text"
        startIcon={<ArrowRightIcon />}
        onClick={() => {
          Event("cancelled_onboarding");
          finish();
        }}
        sx={{
          position: "absolute",
          right: 16,
          top: -40,
          color: "white",
          zIndex: 11,
        }}
      >
        Skip Intro
      </Button>

      <Grid container>
        {/* Sidebar */}
        <Grid
          size={{ md: 3, xs: 12 }}
          sx={{
            color: "white",
            backgroundColor: "primary.main",
            p: 2,
            borderRadius: { xs: "8px 8px 0 0", md: "8px 0 0 8px" },
          }}
        >
          <Logo sx={{ mb: 2 }} />
          <Box sx={{ mt: 2 }}>
            {/* <CheckList
              color="white"
              sx={{ width: "100%" }}
              hideCross
              interactive
              items={props.views.map((view, i) => ({
                name: view.name,
                checked: i <= activeView,
                onClick: () => goTo(i),
              }))}
            /> */}
          </Box>
        </Grid>

        {/* Main Content */}
        <Grid
          size={{ md: 9, xs: 12 }}
          sx={{
            backgroundColor: "background.paper",
            p: 3,
            pt: 2,
            pb: 2,
            borderRadius: { xs: "0 0 8px 8px", md: "0 8px 8px 0" },
            minHeight: "28em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <header>
              <Typography
                variant="h6"
                sx={{ textTransform: "capitalize", mb: 1 }}
              >
                {view.name}
              </Typography>
              {view.description && (
                <Typography variant="body2" color="textSecondary">
                  {view.description}
                </Typography>
              )}
            </header>
            <Box sx={{ mt: 3 }}>{view.component}</Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {activeView > 0 && (
              <Button
                variant="text"
                startIcon={<ArrowLeftIcon />}
                onClick={() => goTo(activeView - 1)}
              >
                Prev
              </Button>
            )}

            {activeView < props.views.length - 1 ? (
              <Button
                variant="text"
                endIcon={<ArrowRightIcon />}
                onClick={() => goTo(activeView + 1)}
                sx={{ marginLeft: "auto" }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="text"
                endIcon={<CheckCircleIcon />}
                onClick={() => {
                  Event("completed_onboarding");
                  finish();
                }}
                sx={{ marginLeft: "auto" }}
              >
                Finish
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
