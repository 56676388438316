import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Alert,
  Modal,
  IconButton,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import FileCopy from "@mui/icons-material/FileCopy";
import { useLocation, useNavigate } from "react-router";
import { Animate, AccountNav, useAPI, ViewContext } from "components/lib";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useContext } from "react";
import { Fragment } from "react";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Key name is required"),
  scope: Yup.array().min(1, "Please select at least one scope."),
});

export function APIKeyEditor(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(ViewContext);

  const scopes = useAPI("/api/scopes");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [newAPIKey, setNewAPIKey] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Modal state

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: data?.name || "",
      scope: [],
    },
  });

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await Axios.get(`/api/key/${location.search?.substring(4)}`);
      if (res.data.data.length) {
        const apiKeyData = res.data.data[0];
        setData(apiKeyData);
        setValue("name", apiKeyData.name || "");
        setValue("scope", apiKeyData.scope || []); // Initialize with existing scopes
      } else {
        navigate("/404");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [location.search, navigate, setValue]);

  useEffect(() => {
    // editing existing key?
    const id = location.search;
    if (id) fetch(id);
  }, [fetch, location.search]);

  const onSubmit = async (formData) => {
    setLoading(true);

    const apiUrl = data ? `/api/key/${data.id}` : "/api/key";
    const method = data ? "PATCH" : "POST";

    try {
      const res = await Axios({
        method: method,
        url: apiUrl,
        data: {
          name: formData.name,
          scope: formData.scope, // Pass the selected scopes as an array
        },
      });

      if (!data) {
        setNewAPIKey(res.data.data.full_key); // Store the newly created API key
        setModalOpen(true); // Open modal when new key is created
      } else {
        context.notification.show("Key saved successfully", "success");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newAPIKey);
    context.notification.show("Private key copied to clipboard", "success");
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate("/apikeys"); // Redirect back to API keys page
  };

  if (scopes.loading || loading) return <CircularProgress />;

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => navigate("/apikeys")}
        sx={{ mb: 3 }} // Add margin bottom
      >
        Back
      </Button>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            height: "auto",
            overflowY: "auto", // Enable vertical scrolling if content overflows
          }}
        >
          <Typography variant="h6" mb={2}>
            Your New API Key
          </Typography>
          <Alert severity="warning">
            <Typography>
              Your API key is below. Please store this somewhere safe.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
              }}
            >
              <TextField
                fullWidth
                value={newAPIKey}
                disabled
                multiline // Allow for better text wrapping
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleCopyToClipboard}>
                      <FileCopy />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
            sx={{ mt: 2 }}
          >
            Back to API Keys
          </Button>
        </Box>
      </Modal>

      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Key Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={
                    errors.name?.message ||
                    "Please provide a descriptive name for your key."
                  }
                />
              )}
            />

            <Typography sx={{ mt: 2 }}>Scope</Typography>
            <FormGroup
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)", // 4 columns
                gap: 2, // Adjust gap between checkboxes
              }}
            >
              {scopes.data?.map((scope) => (
                <Controller
                  key={scope}
                  name="scope"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          value={scope}
                          checked={field.value.includes(scope)}
                          onChange={(event) => {
                            const value = event.target.value;
                            const checked = event.target.checked;
                            field.onChange(
                              checked
                                ? [...field.value, value] // Add scope if checked
                                : field.value.filter((item) => item !== value) // Remove scope if unchecked
                            );
                          }}
                        />
                      }
                      label={scope}
                    />
                  )}
                />
              ))}
            </FormGroup>
            {errors.scope && (
              <Typography color="error">{errors.scope.message}</Typography>
            )}

            <Box mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : data ? (
                  "Save Changes"
                ) : (
                  "Create New API Key"
                )}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Fragment>
  );
}
