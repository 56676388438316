import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";

const UpgradePopup = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    onClose();
    setTimeout(() => {
      navigate("/plans-and-payments");
    }, 200);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography variant="h6" align="center" fontWeight="bold" gutterBottom>
          Your trial plan will expire in
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={4}
          mt={2}
          mb={4}
        >
          <Box textAlign="center">
            <Typography variant="h4" fontWeight="bold" color="primary">
              6
            </Typography>
            <Typography variant="caption">DAYS</Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h4" fontWeight="bold" color="primary">
              23
            </Typography>
            <Typography variant="caption">HOURS</Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h4" fontWeight="bold" color="primary">
              58
            </Typography>
            <Typography variant="caption">MIN</Typography>
          </Box>
        </Box>
        <Typography variant="body2" align="center" gutterBottom>
          After <strong>January 14, 2025</strong>, you will lose access to the
          following features:
        </Typography>
        <Box mt={2} mb={4}>
          {[
            "Your dynamic QR codes will no longer be scannable.",
            "You will not be able to create new QR codes or edit existing ones.",
            "You will lose access to all of your tracking metrics.",
          ].map((text, index) => (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              key={index}
              mt={index === 0 ? 0 : 1}
            >
              <CloseOutlined color="error" fontSize="small" />
              <Typography variant="body2">{text}</Typography>
            </Box>
          ))}
        </Box>
        <Typography variant="body2" align="center">
          To continue using Prolinks without interruption, upgrade to one of our
          plans.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Close
        </Button>
        <Button
          onClick={handleUpgradeClick}
          variant="contained"
          color="primary"
          fullWidth
        >
          Upgrade now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradePopup;
