import * as Yup from "yup";

export const linkConfigurationSchema = Yup.object().shape({
  folder_id: Yup.string().optional().nullable(),
  scheduleActive: Yup.boolean().nullable(),
  startDate: Yup.date().when("scheduleActive", {
    is: true,
    then: () =>
      Yup.date()
        .required("Start date is required")
        .typeError("Invalid start date format"),
    otherwise: () => Yup.mixed().nullable().optional(),
  }),
  endDate: Yup.date()
    .typeError("End date must be a valid date")
    .when("scheduleActive", {
      is: true,
      then: () =>
        Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after the start date")
          .typeError("Invalid end date format"),
      otherwise: () => Yup.mixed().nullable().optional(),
    }),
  urlActive: Yup.boolean().nullable(),
  customUrl: Yup.string().when("urlActive", {
    is: true,
    then: () =>
      Yup.string()
        .required("Url cannot be empty")
        .matches(
          /^[a-zA-Z0-9-_]+$/,
          "Url must contain only letters and numbers, no spaces"
        )
        .max(64, "Url cannot be longer than 64 characters")
        .typeError("Invalid String"),
    otherwise: () => Yup.mixed().nullable().optional(),
  }),
  scanLimitActive: Yup.boolean().nullable(),
  scanLimit: Yup.number().when("scanLimitActive", {
    is: true,
    then: () =>
      Yup.number().required().typeError("Scan limit should be a number"),
    otherwise: () => Yup.mixed().nullable().optional(),
  }),
});
