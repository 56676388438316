import React, { Fragment, useContext } from "react";
import { AuthContext, Logo } from "components/lib";
import { Box, Button as MuiButton, Link, Typography } from "@mui/material";
import { NavLink } from "react-router";

export function HomeNav(props) {
  const context = useContext(AuthContext);

  return (
    <Box
      sx={{
        paddingTop: "8px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "0 16px",
        height: "64px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box width={140} height={40} sx={{ marginRight: 2 }}>
          <Logo color="blue" />
        </Box>
        <Link
          component={NavLink}
          to="/pricing"
          sx={{
            textDecoration: "none",
            fontSize: "1rem",
            fontWeight: 600,
            color: "darkgrey",
            "&:hover": { color: "darkgrey" },
          }}
        >
          Pricing
        </Link>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {context.user ? (
          <MuiButton
            variant="contained"
            size="medium"
            href="/links"
            sx={{ marginLeft: 2 }}
          >
            Dashboard
          </MuiButton>
        ) : (
          <Fragment>
            <Typography
              variant="body2"
              sx={{
                marginRight: 2,
                color: "grey",
                "& a": {
                  textDecoration: "none",
                  fontWeight: 600,
                  color: "inherit",
                  "&:hover": { color: "darkgrey" },
                },
              }}
            >
              <Link component={NavLink} to="/signin">
                Login
              </Link>
            </Typography>
            <MuiButton
              variant="contained"
              color="secondary" // Changed to secondary color
              size="medium"
              href="/signup"
            >
              Sign up
            </MuiButton>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}
