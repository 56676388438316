import React, { Fragment, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios"; // Ensure you have axios installed
import { Row, Content, Message } from "components/lib";
import AccountDetails from "./accountDetails";
import AccountSettings from "./accountSettings";
import AccountInvoices from "./accountInvoices";
import { BlockOutlined, DeleteOutline } from "@mui/icons-material";
import { AccountUsers } from "./accountUsers";

export function AccountsDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Get account ID from URL parameters
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "",
    description: "",
    action: null,
  });

  // Define the tab routes and labels
  const tabRoutes = [
    { label: "Details", path: "details" },
    { label: "Users", path: "users" },
    { label: "Settings", path: "settings" },
    { label: "Invoices", path: "invoices" },
  ];

  // Determine the current tab based on the URL
  const currentTab = tabRoutes.findIndex((tab) =>
    location.pathname.endsWith(tab.path)
  );

  const handleTabChange = (event, newValue) => {
    navigate(tabRoutes[newValue].path);
  };

  const openDialog = (title, description, action) => {
    setDialogState({ open: true, title, description, action });
  };

  const closeDialog = () => {
    setDialogState({ open: false, title: "", description: "", action: null });
  };

  const handleDeactivate = async () => {
    try {
      await axios.post(`/api/master/accounts/${id}/deactivate`);
      Message.success("Account deactivated successfully");
      closeDialog();
      navigate("/master/accounts");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/master/accounts/${id}`);
      Message.success("Account deleted successfully");
      closeDialog();
      navigate("/master/accounts");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/master/accounts")}
            sx={{ cursor: "pointer" }}
          >
            Accounts
          </Link>
          <Typography color="text.primary">
            {tabRoutes[currentTab !== -1 ? currentTab : 0].label}
          </Typography>
        </Breadcrumbs>

        <Box>
          <Button
            variant="outlined"
            color="error"
            startIcon={<BlockOutlined />}
            onClick={() =>
              openDialog(
                "Deactivate Account",
                "Are you sure you want to deactivate this account? This action cannot be undone.",
                handleDeactivate
              )
            }
            sx={{ marginRight: 1 }}
          >
            Deactivate
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteOutline />}
            onClick={() =>
              openDialog(
                "Delete Account",
                "Are you sure you want to delete this account? This is not reversible and will delete all links associated with this account.",
                handleDelete
              )
            }
          >
            Delete
          </Button>
        </Box>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab !== -1 ? currentTab : 0}
          onChange={handleTabChange}
          aria-label="Account details tabs"
        >
          {tabRoutes.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Routes>
          <Route path="/" element={<AccountDetails />} />
          <Route path="details" element={<AccountDetails />} />
          <Route path="users" element={<AccountUsers />} />
          <Route path="settings" element={<AccountSettings />} />
          <Route path="invoices" element={<AccountInvoices />} />
        </Routes>
      </Box>

      {/* Shared Confirmation Dialog */}
      <Dialog
        open={dialogState.open}
        onClose={closeDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          {dialogState.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {dialogState.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={dialogState.action}
            color="error"
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
