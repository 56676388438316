import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  Popover,
  Snackbar,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Folder as FolderIcon,
  AddOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router";
import { BlankSlateMessage, useAPI } from "components/lib";
import Grid from "@mui/material/Grid2";

export function AccountFolders() {
  const [folders, setFolders] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const { data, error, refetch, loading } = useAPI("api/folders");

  useEffect(() => {
    if (data) {
      setFolders(data);
    }
  }, [data]);

  const handleMoreClick = (event, folder) => {
    setAnchorEl(event.currentTarget);
    setSelectedFolder(folder);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/folders/${selectedFolder.id}`);
    handlePopoverClose();
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`api/folders/${selectedFolder.id}`);
      refetch(); // Refetch the data instead of filtering manually
      setSnackbarMessage("Folder deleted successfully");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to delete folder");
      setSnackbarOpen(true);
    }
    setConfirmDeleteOpen(false);
    handlePopoverClose();
  };

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true);
    handlePopoverClose();
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleCreateFolder = () => {
    navigate("/folders/create");
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={handleCreateFolder}
        >
          Create Folder
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <CircularProgress />
        </Box>
      ) : folders.length === 0 ? (
        <BlankSlateMessage
          title="You haven't created a folder yet"
          action={() => navigate("/folders/create")}
          buttonText="Create Folder"
        />
      ) : (
        <Grid container spacing={2}>
          {folders.map((folder) => (
            <Grid size={{ sm: 4, xs: 12, md: 2 }} key={folder.id}>
              <Card
                sx={{
                  height: "150px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMoreClick(event, folder)}
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    <MoreVertIcon fontSize="medium" />
                  </IconButton>
                  <FolderIcon
                    fontSize="large"
                    sx={{ color: "primary.main", marginBottom: "10px" }}
                  />
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {folder.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            padding: 1,
            minWidth: 200,
          },
        }}
      >
        <MenuItem onClick={handleEdit} sx={{ padding: "10px 20px" }}>
          <EditIcon sx={{ marginRight: "10px" }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteConfirm} sx={{ padding: "10px 20px" }}>
          <DeleteIcon sx={{ marginRight: "10px" }} />
          Delete
        </MenuItem>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        color="success"
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the folder '{selectedFolder?.name}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
