import React, { useContext, useState } from "react";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { QrCodeUI } from "components/qrcode/qrcode";
import { ViewContext } from "components/lib";
import { SubmitForm } from "./submit";
import { UpdateForm } from "./update";
import { linkConfigurationSchema } from "components/linkConfigurations/linkConfigurationSchema";
import { LinkConfigurations } from "components/linkConfigurations/linkConfigurations";
import { getDefaultConfigurationValues } from "constants/shared";

// Define the main schema for WebsitesCreate with nested object reference
const websiteSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(32, "Name must be at most 64 characters long"),
  url: Yup.string()
    .required("URL is required")
    .matches(
      /^(https:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
      "Please enter a valid website URL (e.g., https://www.example.com)"
    ),
  // Use linkConfigurationSchema as a nested schema for linkConfigurations
  linkConfigurations: linkConfigurationSchema,
});

export function WebsitesCreate({ resource }) {
  const isEditMode = !!resource?.id;
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(websiteSchema),
    defaultValues: {
      name: resource?.id ? resource.name : "",
      url: resource?.id ? resource?.meta_data?.url : "",
      ...getDefaultConfigurationValues(resource),
    },
  });

  const [qrCodeOptions, setQRCodeOptions] = useState(
    resource?.id ? resource.qr_options : null
  );

  const handleQRCodeChange = (options) => {
    setQRCodeOptions(options);
  };

  const onSubmit = async (data) => {
    const requestData = {
      type: "website",
      name: data.name,
      qrCode: qrCodeOptions,
      linkConfigurations: data.linkConfigurations,
      data: {
        url: data.url,
      },
    };

    try {
      if (isEditMode) {
        await axios.put("/api/links/" + resource?.id, requestData);
        context.notification.show("Update successful!", "success");
      } else {
        const res = await axios.post("/api/links", requestData);
        context.notification.show("QR Code generated!", "success");
        navigate("/links/" + res.data.id + "/edit");
      }
    } catch (err) {
      console.log(err);
      context.notification.show(
        err?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box marginTop={2}>
          <Box display={"flex"} alignItems="center" marginBottom={2}>
            <Typography display={"flex"}>Enter a Name</Typography>
          </Box>
          <Box display={"block"} marginTop={1} mb={2}>
            {/* React Hook Form Controller for the Name Field */}
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="Name"
                  fullWidth
                  error={!!methods.formState.errors.name}
                  helperText={
                    methods.formState.errors.name
                      ? methods.formState.errors.name.message
                      : ""
                  }
                />
              )}
            />
          </Box>
        </Box>

        <Box marginTop={2}>
          <Box display={"flex"} alignItems="center" marginBottom={2}>
            <Typography display={"flex"}>Enter the Link URL</Typography>
          </Box>
          <Box display={"block"} marginTop={1} mb={2}>
            {/* React Hook Form Controller for the URL Field */}
            <Controller
              name="url"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="https://www.example.com"
                  fullWidth
                  error={!!methods.formState.errors.url}
                  helperText={
                    methods.formState.errors.url
                      ? methods.formState.errors.url.message
                      : ""
                  }
                />
              )}
            />
          </Box>
        </Box>

        <Box marginTop={2}>
          <LinkConfigurations
            control={methods.control}
            errors={methods.formState.errors}
            isEditMode={isEditMode}
          />{" "}
        </Box>

        {isEditMode && (
          <Box marginTop={2}>
            <Card variant="outlined" style={{ overflow: "visible" }}>
              <CardContent>
                <QrCodeUI
                  config={qrCodeOptions}
                  onChange={handleQRCodeChange}
                ></QrCodeUI>
              </CardContent>
            </Card>
          </Box>
        )}

        {isEditMode ? (
          <UpdateForm onUpdate={methods.handleSubmit(onSubmit)} />
        ) : (
          <SubmitForm onSubmit={methods.handleSubmit(onSubmit)} />
        )}
      </FormProvider>
    </>
  );
}
