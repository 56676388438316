import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";

const ErrorInterceptor = ({ children }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);
  const handleUpgrade = () => {
    setOpen(false);
    navigate("/plans-and-payments");
  };

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response } = error;
      if (response?.data?.code === "OP_001") {
        setOpen(true);
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      {children}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={1}>
            <WarningOutlined color="error" fontSize="large" />
            <Typography variant="h6" align="center">
              Plan Doesn't Allow This Operation
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            You have exceeded your limit or not allowed to perform this
            operation. Please check your account plan.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpgrade} color="primary" variant="contained">
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorInterceptor;
