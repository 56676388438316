import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAPI, ViewContext } from "components/lib";
import { DownloadDoneOutlined } from "@mui/icons-material";

const BulkCreateSchema = Yup.object().shape({
  folderId: Yup.string().optional("Folder is required"),
});

export function BulkCreate({ type }) {
  const context = useContext(ViewContext);
  const { data: folders, refetch } = useAPI("api/folders");
  const [csvData, setCsvData] = useState(null);
  const [rowCount, setRowCount] = useState(0);

  const methods = useForm({
    resolver: yupResolver(BulkCreateSchema),
    defaultValues: {
      folderId: "",
    },
  });

  const handleCsvUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const rows = text.split("\n").filter((row) => row.trim());
      const headers = rows
        .shift()
        .split(",")
        .map((header) => header.trim());
      const data = rows.map((row) => {
        const values = row.split(",").map((value) => value.trim());
        return headers.reduce(
          (acc, header, index) => ({ ...acc, [header]: values[index] }),
          {}
        );
      });

      if (data.length > 1200) {
        context.notification.show(
          "File contains more than 1200 rows.",
          "error"
        );
        setCsvData(null);
        setRowCount(0);
      } else {
        setCsvData(data);
        setRowCount(data.length);
      }
    };
    reader.readAsText(file);
  };

  const onSubmit = async (formData) => {
    if (!csvData) {
      context.notification.show("Please upload a valid CSV file.", "error");
      return;
    }

    const requestData = {
      folderId: formData.folderId,
      type,
      data:
        type === "website"
          ? csvData.map((row) => ({
              name: row.qrName,
              url: row.websiteUrl,
            }))
          : csvData.map((row) => ({
              name: row.name,
              productName: row.productName,
              productDescription: row.productDescription,
              subTotal: row.subTotal,
              tax: row.tax,
              totalNet: row.totalNet,
            })),
    };

    try {
      await axios.post("/api/bulk-generate", requestData);
      context.notification.show("Bulk generation successful!", "success");
    } catch (error) {
      context.notification.show(
        error?.response?.data?.message || "Bulk generation failed.",
        "error"
      );
    }
  };

  const triggerFileUpload = () => {
    document.getElementById("csvFileInput").click();
  };

  const downloadSampleCsv = () => {
    const headers =
      type === "website"
        ? ["qrName", "websiteUrl"]
        : [
            "name",
            "productName",
            "productDescription",
            "subTotal",
            "tax",
            "totalNet",
          ];

    const csvContent = headers.join(",") + "\n";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${type}-sample.csv`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <FormProvider {...methods}>
      <Box marginTop={2}>
        <Box marginBottom={2}>
          <Typography>
            Choose the folder where your {type}s will be stored
          </Typography>
          <Controller
            name="folderId"
            control={methods.control}
            render={({ field }) => (
              <Select {...field} fullWidth size="small" displayEmpty>
                <MenuItem value="" disabled>
                  No folder
                </MenuItem>
                {folders &&
                  folders.map((folder) => (
                    <MenuItem key={folder.id} value={folder.id}>
                      {folder.name}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </Box>

        <Box marginBottom={4}>
          <Card variant="outlined" sx={{ padding: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Download the CSV example</Typography>
              <Button
                variant="outlined"
                onClick={downloadSampleCsv}
                startIcon={<DownloadDoneOutlined />}
              >
                Download
              </Button>
            </Box>
          </Card>
        </Box>

        <Typography>Complete the sample CSV and re-upload it</Typography>
        <Box marginBottom={4} textAlign="center">
          <Card variant="outlined" sx={{ padding: 4, marginTop: 2 }}>
            <input
              id="csvFileInput"
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              onChange={(e) => handleCsvUpload(e.target.files[0])}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={triggerFileUpload}
              size="large"
            >
              Upload CSV
            </Button>
            {rowCount > 0 && (
              <Typography marginTop={2}>
                File contains {rowCount} rows.
              </Typography>
            )}
          </Card>
        </Box>

        {csvData && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}
