import React, { useState } from "react";
import { IconButton, Typography, Button, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";

export function Message(props) {
  const navigate = useNavigate();

  // State
  const [closed, setClosed] = useState(false);
  const type = props.type || "info";

  if (closed) return null;

  // Icon mapping
  const icons = {
    info: <InfoIcon style={{ color: "#2196f3" }} />,
    success: <CheckCircleIcon style={{ color: "#4caf50" }} />,
    warning: <WarningAmberIcon style={{ color: "#ff9800" }} />,
    error: <ErrorIcon style={{ color: "#f44336" }} />,
  };

  // Color mapping
  const colors = {
    info: "#e3f2fd",
    success: "#e8f5e9",
    warning: "#fff3e0",
    error: "#ffebee",
  };

  // Styles
  const containerStyle = {
    backgroundColor: colors[type],
    padding: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    position: "relative",
    width: "100%",
  };

  const iconStyle = {
    marginRight: "16px",
    display: "flex",
    alignItems: "center",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "8px",
    right: "8px",
  };

  return (
    <Box style={containerStyle}>
      <Box style={iconStyle}>{icons[type]}</Box>

      <Box flex="1">
        {props.title && (
          <Typography
            variant="h6"
            component="h1"
            color="textPrimary"
            gutterBottom
          >
            {props.title}
          </Typography>
        )}

        {props.text && (
          <Typography variant="body2" color="textSecondary">
            {props.text}
          </Typography>
        )}

        {props.children}

        {props.buttonLink && (
          <Button
            variant="contained"
            color={type}
            onClick={() => navigate(props.buttonLink)}
            style={{ marginTop: "8px" }}
          >
            {props.buttonText || "Learn More"}
          </Button>
        )}
      </Box>

      {props.closable && (
        <IconButton
          aria-label="close"
          onClick={() => setClosed(true)}
          style={closeButtonStyle}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}
