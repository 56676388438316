import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "components/lib";
import { Typography, TextField, Button, Card, Box } from "@mui/material";

const schema = yup
  .object({
    code: yup.string().required("Verification code is required"),
  })
  .required();

export function SigninOTP() {
  const location = useLocation();
  const context = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const token = location?.search?.substring(7);

  const onSubmit = async (data) => {
    const { code } = data;
    try {
      const response = await axios.post("/api/auth/otp", {
        code,
        jwt: token,
      });
      context.signin(response);
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={2}
      marginTop={8}
    >
      <Typography variant="h5" gutterBottom color="primary">
        Enter Verification Code
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Please enter the verification code from your authenticator app.
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Verification Code"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.code}
              helperText={errors.code ? errors.code.message : ""}
            />
          )}
        />
        <Button type="submit" variant="contained" color="success" fullWidth>
          Complete Sign In
        </Button>
      </form>

      <Typography variant="body2" color="textSecondary" mt={2}>
        Lost access to your authenticator app? Please enter your backup code
        above.
      </Typography>
    </Box>
  );
}
