import React, { useContext, useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  ListItemIcon,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import { SubmitForm } from "./submit";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { linkConfigurationSchema } from "components/linkConfigurations/linkConfigurationSchema";
import { LinkConfigurations } from "components/linkConfigurations/linkConfigurations";
import { getDefaultConfigurationValues } from "constants/shared";
import { ViewContext } from "components/lib";
import { UpdateForm } from "./update";
import {
  InfoOutlined,
  InterpreterModeOutlined,
  ProductionQuantityLimitsOutlined,
  RectangleOutlined,
} from "@mui/icons-material";

// Define the schema for product creation
const productSchema = Yup.object().shape({
  name: Yup.string()
    .required("Link name is required")
    .min(3, "Name must be at least 3 characters long")
    .max(64, "Name must be at most 64 characters long"),
  productName: Yup.string().required("Product name is required"),
  productDescription: Yup.string()
    .required("Product description is required")
    .max(256, "Description must be at most 256 characters long"),
  subTotal: Yup.number()
    .required("Subtotal is required")
    .min(0, "Subtotal cannot be negative"),
  tax: Yup.number()
    .required("Tax is required")
    .min(0, "Tax cannot be negative"),
  totalNet: Yup.number()
    .required("Total net is required")
    .min(0, "Total net cannot be negative"),
  linkConfigurations: linkConfigurationSchema,
});

export function ProductCreate({ resource }) {
  const isEditMode = resource?.id;
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const [previewContent, setPreviewContent] = useState({
    productName: resource?.id ? resource?.meta_data?.productName || "" : "",
    productDescription: resource?.id
      ? resource?.meta_data?.productDescription || ""
      : "",
    subTotal: resource?.id ? resource?.meta_data?.subTotal || 0 : 0,
    tax: resource?.id ? resource?.meta_data?.tax || 0 : 0,
    totalNet: resource?.id ? resource?.meta_data?.totalNet || 0 : 0,
  });

  const methods = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      name: resource?.id ? resource?.name : "",
      productName: resource?.id ? resource?.meta_data?.productName || "" : "",
      productDescription: resource?.id
        ? resource?.meta_data?.productDescription || ""
        : "",
      subTotal: resource?.id ? resource?.meta_data?.subTotal || 0 : 0,
      tax: resource?.id ? resource?.meta_data?.tax || 0 : 0,
      totalNet: resource?.id ? resource?.meta_data?.totalNet || 0 : 0,
      ...getDefaultConfigurationValues(resource),
    },
  });

  const onSubmit = async (data) => {
    const requestData = {
      type: "product",
      name: data.name,
      linkConfigurations: data.linkConfigurations,
      data: {
        productName: data.productName,
        productDescription: data.productDescription,
        subTotal: data.subTotal,
        tax: data.tax,
        totalNet: data.totalNet,
      },
    };

    try {
      if (isEditMode) {
        await axios.put("/api/links/" + resource?.id, requestData);
        context.notification.show("Update successful!", "success");
      } else {
        const res = await axios.post("/api/links", requestData);
        context.notification.show("Product created!", "success");
        navigate("/links/" + res.data.id + "/edit");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updatePreviewContent = (field, value) => {
    setPreviewContent((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <FormProvider {...methods}>
      <Box display="flex" marginTop={2}>
        {/* Left Side Form */}
        <Box flex={1} marginRight={2}>
          {/* Link Name Field */}
          <Box marginBottom={2}>
            <Box display={"flex"} alignItems="center" marginBottom={2}>
              Enter a Name
            </Box>
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  placeholder="Link Name"
                  fullWidth
                  error={!!methods.formState.errors.name}
                  helperText={methods.formState.errors.name?.message || ""}
                />
              )}
            />
          </Box>

          {/* Product Details in Collapsible Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InfoOutlined
                sx={{ fontSize: 40, color: "primary.main", mr: 2 }}
              />
              <Box>
                <Typography>Product Details</Typography>
                <Typography variant="body2" color="textSecondary">
                  Define your product information here
                </Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              {[
                {
                  title: "Product Name",
                  name: "productName",
                  placeholder: "Product Name",
                },
                {
                  title: "Product Description",
                  name: "productDescription",
                  placeholder: "Product Description",
                  multiline: true,
                  rows: 3,
                },
                {
                  title: "Subtotal",
                  name: "subTotal",
                  placeholder: "Subtotal ($)",
                  type: "number",
                },
                {
                  title: "Tax",
                  name: "tax",
                  placeholder: "Tax ($)",
                  type: "number",
                },
                {
                  title: "Total Net",
                  name: "totalNet",
                  placeholder: "Total Net ($)",
                  type: "number",
                },
              ].map(({ title, name, placeholder, ...props }) => (
                <Box key={name} marginBottom={2}>
                  <Box display={"flex"} alignItems="center" marginBottom={2}>
                    <Typography display={"flex"}>{title}</Typography>
                  </Box>
                  <Controller
                    name={name}
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        placeholder={placeholder}
                        fullWidth
                        {...props}
                        error={!!methods.formState.errors[name]}
                        helperText={
                          methods.formState.errors[name]?.message || ""
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          updatePreviewContent(name, e.target.value);
                        }}
                      />
                    )}
                  />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>

          <Box marginTop={2}>
            <LinkConfigurations
              control={methods.control}
              errors={methods.formState.errors}
              isEditMode={isEditMode}
            />
          </Box>

          {isEditMode ? (
            <UpdateForm onUpdate={methods.handleSubmit(onSubmit)} />
          ) : (
            <SubmitForm onSubmit={methods.handleSubmit(onSubmit)} />
          )}
        </Box>

        <Box
          flex={0.2}
          display="flex"
          padding={1}
          marginLeft={1}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="300px"
            height="600px"
            borderRadius="20px"
            boxShadow="0 4px 10px rgba(0, 0, 0, 0.15)"
            overflow="hidden"
            bgcolor="#f9f9f9"
            display="flex"
            flexDirection="column"
            position="relative"
          >
            <Box
              width="100%"
              height="100%"
              overflow="auto"
              padding={3}
              bgcolor="linear-gradient(135deg, #ffffff, #f7f7f7)"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant="h5"
                  gutterBottom
                  fontWeight="bold"
                  color="text.primary"
                  textAlign="center"
                  sx={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                  }}
                >
                  {previewContent.productName}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  color="text.secondary"
                  textAlign="center"
                >
                  {previewContent.productDescription}
                </Typography>
              </Box>
              <Box marginTop={3}>
                {[
                  { label: "Subtotal", value: previewContent.subTotal },
                  { label: "Tax", value: previewContent.tax },
                  { label: "Total Net", value: previewContent.totalNet },
                ].map(({ label, value }) => (
                  <Box
                    key={label}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                  >
                    <Typography variant="subtitle1" color="text.primary">
                      {label}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="text.secondary"
                    >
                      ${value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
