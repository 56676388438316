import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  Popover,
  Snackbar,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  QrCode2Outlined,
  Add as AddIcon,
  AddOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router";
import { BlankSlateMessage, useAPI } from "components/lib";
import QRCodeStyling from "qr-code-styling";
import { useRef } from "react";
import Grid from "@mui/material/Grid2";

export function AccountTemplates() {
  const [templates, setTemplates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const qrRefs = useRef([]);
  const navigate = useNavigate();

  const { data, error, refetch, loading } = useAPI("api/templates");

  useEffect(() => {
    if (data) {
      setTemplates(data);
    }
  }, [data]);

  useEffect(() => {
    templates.forEach((item, index) => {
      const container = qrRefs.current[index];
      if (container) {
        container.innerHTML = ""; // Clear previous QR code

        const qrCode = new QRCodeStyling({
          ...item.qr_options,
          width: 110,
          height: 110,
          margin: 2,
        });
        qrCode.append(qrRefs.current[index]);
      }
    });
  }, [templates]);

  const handleMoreClick = (event, template) => {
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(template);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/templates/${selectedTemplate.id}`);
    handlePopoverClose();
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`api/templates/${selectedTemplate.id}`);
      refetch(); // Refetch the data instead of filtering manually
      setSnackbarMessage("Template deleted successfully");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to delete template");
      setSnackbarOpen(true);
    }
    setConfirmDeleteOpen(false);
    handlePopoverClose();
  };

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true);
    handlePopoverClose();
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleCreateTemplate = () => {
    navigate("/templates/create");
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={handleCreateTemplate}
        >
          Create Template
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <CircularProgress />
        </Box>
      ) : templates.length === 0 ? (
        <BlankSlateMessage
          title="You haven't created a template yet"
          action={() => navigate("/templates/create")}
          buttonText="Create Template"
        ></BlankSlateMessage>
      ) : (
        <Grid container spacing={3}>
          {templates.map((template, index) => (
            <Grid size={{ sm: 6, xs: 12, md: 3 }} key={template.id}>
              <Card
                sx={{
                  height: "230px",
                  position: "relative",
                }}
              >
                <CardContent>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => handleMoreClick(event, template)}
                    sx={{
                      position: "absolute", // Position the IconButton absolutely
                      top: 15,
                      right: 15,
                    }}
                  >
                    <MoreVertIcon fontSize={"medium"} />
                  </IconButton>
                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <Box
                      display={"inline-block"}
                      key={template.id}
                      width={100}
                      height={100}
                      ref={(el) => (qrRefs.current[index] = el)}
                    />{" "}
                  </Box>
                  <hr />
                  <Typography variant="h6" sx={{ textAlign: "center", mt: 1 }}>
                    {template.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            padding: 1, // Add padding to make the popover content larger
            minWidth: 200, // Set a minimum width to make it larger
          },
        }}
      >
        <MenuItem onClick={handleEdit} sx={{ padding: "10px 20px" }}>
          <EditIcon sx={{ marginRight: "10px" }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteConfirm} sx={{ padding: "10px 20px" }}>
          <DeleteIcon sx={{ marginRight: "10px" }} />
          Delete
        </MenuItem>
      </Popover>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        color="success"
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the template '
            {selectedTemplate?.name}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
