import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  MenuItem,
  Breadcrumbs,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router";
import { ViewContext } from "components/lib";

export function ContactsCreate() {
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the route params to check if it's edit mode
  const isEditMode = !!id;

  // States for form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [plan, setPlan] = useState("Standard"); // Default plan is "Standard"
  const [errors, setErrors] = useState({});

  // Fetch existing data if in edit mode
  useEffect(() => {
    if (isEditMode) {
      axios.get(`/master/accounts/${id}`).then((res) => {
        const accountData = res.data;
        setName(accountData.name);
        setEmail(accountData.email || "");
        setPlan(accountData.plan || "Standard");
      });
    }
  }, [id, isEditMode]);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Manual validation function
  const validateFields = () => {
    let tempErrors = {};

    if (!name) tempErrors.name = "Account name is required.";
    if (!email) {
      tempErrors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      tempErrors.email = "Invalid email format.";
    }
    if (!password && !isEditMode) tempErrors.password = "Password is required.";
    if (!confirmPassword && !isEditMode)
      tempErrors.confirmPassword = "Confirm Password is required.";
    if (password !== confirmPassword)
      tempErrors.confirmPassword = "Passwords do not match.";
    if (!plan) tempErrors.plan = "Plan selection is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateFields()) return;

    const requestData = {
      name: name,
      email: email,
      password: password,
      plan: plan,
    };

    try {
      if (isEditMode) {
        await axios.put(`/api/master/accounts/${id}`, requestData);
        context.notification.show("Account updated successfully!", "success");
      } else {
        const res = await axios.post("/api/master/accounts", requestData);
        navigate("/accounts/" + res.data.id + "/edit");
        context.notification.show("Account created successfully!", "success");
      }
    } catch (err) {
      console.error("Error submitting form: ", err);
      context.notification.show(
        "Failed to submit the form. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      <Box marginTop={2}>
        <Box display={"flex"} alignItems="center">
          <Typography display={"flex"}>Account Name</Typography>
        </Box>
        <Box display={"block"} marginTop={1} mb={2}>
          <TextField
            size="small"
            value={name}
            onChange={(event) => setName(event.target.value)}
            id="account-name"
            placeholder="Enter Account Name"
            fullWidth
            error={!!errors.name}
            helperText={errors.name || ""}
          />
        </Box>

        <Box display={"flex"} alignItems="center">
          <Typography display={"flex"}>Email</Typography>
        </Box>
        <Box display={"block"} marginTop={1} mb={2}>
          <TextField
            size="small"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            id="account-email"
            placeholder="Enter Email"
            type="email"
            fullWidth
            error={!!errors.email}
            helperText={errors.email || ""}
          />
        </Box>

        {!isEditMode && (
          <>
            <Box display={"flex"} alignItems="center">
              <Typography display={"flex"}>Password</Typography>
            </Box>
            <Box display={"block"} marginTop={1} mb={2}>
              <TextField
                size="small"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                id="account-password"
                placeholder="Enter Password"
                type="password"
                fullWidth
                error={!!errors.password}
                helperText={errors.password || ""}
              />
            </Box>

            <Box display={"flex"} alignItems="center">
              <Typography display={"flex"}>Confirm Password</Typography>
            </Box>
            <Box display={"block"} marginTop={1} mb={2}>
              <TextField
                size="small"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                id="account-confirm-password"
                placeholder="Confirm Password"
                type="password"
                fullWidth
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword || ""}
              />
            </Box>
          </>
        )}

        <Box display={"flex"} alignItems="center">
          <Typography display={"flex"}>Plan Selection</Typography>
        </Box>
        <Box display={"block"} marginTop={1} mb={2}>
          <TextField
            select
            size="small"
            value={plan}
            onChange={(event) => setPlan(event.target.value)}
            id="plan-selection"
            fullWidth
            error={!!errors.plan}
            helperText={errors.plan || ""}
          >
            <MenuItem value="starter">Starter</MenuItem>
            <MenuItem value="standard">Standard</MenuItem>
            <MenuItem value="professional">Professional</MenuItem>
            <MenuItem value="enterprise">Enterprise</MenuItem>
          </TextField>
        </Box>

        <Box marginTop={2} display="flex">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {isEditMode ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
