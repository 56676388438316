import React, { useContext } from "react";
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  ImageList,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  AlbumOutlined,
  DocumentScannerOutlined,
  FeedbackOutlined,
  FileOpenOutlined,
  ImageOutlined,
  ImageSearchOutlined,
  LanOutlined,
  LinkOutlined,
  PhotoAlbum,
  PhotoAlbumOutlined,
  PictureAsPdfOutlined,
  ProductionQuantityLimitsOutlined,
  RecommendOutlined,
  SocialDistanceOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { WebsitesCreate } from "./links/website";
import { PDFCreate } from "./links/files";
import { LandingCreate } from "./links/landing";
import { ImageCreate } from "./links/image";
import { PDFList } from "./links/pdfList";
import { ProductCreate } from "./links/product";
import { AuthContext } from "components/lib";

export function AccountLinksCreate() {
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState("website");
  const authContext = useContext(AuthContext);
  const expired = authContext?.user?.isExpired;

  const handleChange = (event, nextView) => {
    if (nextView) {
      setView(nextView);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const LinksMenuButton = ({ value, title, icon, disabled = false }) => {
    return (
      <ToggleButton value={value} disabled={disabled}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={64}
        >
          <Box mb={0.5}>{icon}</Box>
          {title}
        </Box>
      </ToggleButton>
    );
  };

  return (
    <>
      <Box width={"90%"}>
        <Box>
          <ToggleButtonGroup
            orientation="horizontal"
            value={view}
            exclusive
            disabled={expired}
            size="small"
            color="primary"
            onChange={handleChange}
          >
            <LinksMenuButton
              title={"website"}
              value="website"
              icon={<LinkOutlined />}
            />
            <LinksMenuButton
              title={"PDF"}
              value="pdf"
              icon={<FileOpenOutlined />}
            />
            <LinksMenuButton
              title={"PDF List"}
              value="pdf-list"
              disabled={true}
              icon={<PictureAsPdfOutlined />}
            />
            <LinksMenuButton
              title={"Links"}
              disabled={true}
              value="links"
              icon={<LanOutlined />}
            />
            <LinksMenuButton
              title={"Landing"}
              value="landing"
              icon={<DocumentScannerOutlined />}
            />
            <LinksMenuButton
              title={"Product"}
              value="product"
              icon={<ProductionQuantityLimitsOutlined />}
            />
            <LinksMenuButton
              title={"Images"}
              disabled={true}
              value="image"
              icon={<ImageOutlined />}
            />
            <LinksMenuButton
              title={"Video"}
              disabled={true}
              value="video"
              icon={<VideoFileOutlined />}
            />
            <LinksMenuButton
              title={"Album"}
              disabled={true}
              value="album"
              icon={<PhotoAlbumOutlined />}
            />
             
            <LinksMenuButton
              title={"Socials"}
              disabled={true}
              value="socials"
              icon={<RecommendOutlined />}
            ></LinksMenuButton>
            <LinksMenuButton
              title={"Feedback"}
              disabled={true}
              value="feedback"
              icon={<FeedbackOutlined />}
            ></LinksMenuButton>
            {/* <LinksMenuButton
                    title={"vCard"}
                    value="vcard"
                    icon={<ContactsOutlined />}
                  />
                  <LinksMenuButton
                    title={"Social"}
                    value="vcard"
                    icon={<SocialDistanceOutlined />}
                  /> */}
          </ToggleButtonGroup>
        </Box>
        {view === "website" && <WebsitesCreate></WebsitesCreate>}
        {view === "pdf" && <PDFCreate></PDFCreate>}
        {view === "pdf-list" && <PDFList></PDFList>}
        {view === "product" && <ProductCreate></ProductCreate>}
        {view === "links" && <PDFList></PDFList>}
        {view === "landing" && <LandingCreate></LandingCreate>}
        {view === "image" && <ImageCreate></ImageCreate>}
        {/* {view === "landing" && <PDFCreate></PDFCreate>} */}
      </Box>
    </>
  );
}
